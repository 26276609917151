import styled, { css, keyframes } from 'styled-components'

import { ButtonProps } from '.'

type ModifiersProps = Partial<ButtonProps>

export const loadingAnimation = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`

const modifiers = {
  primary: ({ isLoading }: ModifiersProps) => css`
    background: var(--button-background-color);
    color: ${isLoading ? 'transparent' : 'var(--button-text-color)'};

    :disabled {
      background: var(--button-disabled-background-color);
      color: ${isLoading ? 'transparent' : 'var(--button-disabled-text-color)'};
    }

    ${isLoading
      ? css`
          :after {
            border-color: var(--button-loading-primary-color);
            border-left-color: var(--button-loading-secondary-color);
          }

          &:disabled {
            &:after {
              border-color: var(--button-disabled-loading-primary-color);
              border-left-color: var(--button-disabled-loading-secondary-color);
            }
          }
        `
      : css`
          :after {
            display: none;
          }

          :hover:enabled {
            background-color: var(--button-hover-background-color);
          }

          :active {
            background-color: var(--button-active-background-color);
          }
        `}
  `,
  secondary: ({ isLoading }: ModifiersProps) => css`
    color: ${isLoading ? 'transparent' : 'var(--button-secondary-text-color)'};
    border: 1px solid var(--button-secondary-border-color);

    :disabled {
      border-color: var(--button-disabled-background-color);
      color: ${isLoading ? 'transparent' : 'var(--button-disabled-text-color)'};
    }

    ${isLoading
      ? css`
          border-color: var(--button-background-color);

          :after {
            border-color: var(--button-background-color);
            border-left-color: var(--button-secondary-border-color);
          }

          :disabled {
            border-color: var(--button-disabled-text-color);

            :after {
              border-color: var(--button-disabled-text-color);
              border-left-color: var(--button-disabled-background-color);
            }
          }
        `
      : css`
          :after {
            display: none;
          }

          :hover:enabled {
            border-color: var(--button-background-color);
            background-color: var(--button-secondary-hover-background-color) !important;
          }
        `}
  `,
  link: ({ isLoading }: ModifiersProps) => css`
    background: none;
    color: ${isLoading ? 'transparent' : 'var(--button-link-text-color)'};

    :disabled {
      border-color: var(--button-disabled-background-color);
      color: ${isLoading ? 'transparent' : 'var(--button-disabled-text-color)'};
    }

    ${isLoading
      ? css`
          border-color: var(--button-background-color);

          :after {
            border-color: var(--button-background-color);
            border-left-color: var(--button-disabled-background-color);
          }

          :disabled {
            border-color: var(--button-disabled-text-color);

            :after {
              border-color: var(--button-disabled-text-color);
              border-left-color: var(--button-disabled-background-color);
            }
          }
        `
      : css`
          :after {
            display: none;
          }

          :hover:enabled {
            background-color: var(--button-link-hover-background-color);
          }

          :active {
            background: var(--button-background-color);
            color: var(--button-text-color);
          }
        `}
  `,
  withIcon: ({ size }: ModifiersProps) => css`
    justify-content: space-between;

    span + * {
      margin-left: 12px;
    }

    svg {
      ${size === 'default'
        ? css`
            height: var(--button-icon-size);
          `
        : css`
            height: var(--button-icon-size-small);
          `}
    }
  `,
  loading: () => css`
    position: relative;
    outline: none;
    cursor: progress;

    :after {
      content: '';
      border-radius: 50%;
      width: 18px;
      height: 18px;
      z-index: 1;
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: var(--button-loading-font-size);
      border-style: solid;
      border-width: 2px;
      animation: ${loadingAnimation} 1.1s infinite linear;
    }
  `,
  sizeVariant: ({ size }: ModifiersProps) => css`
    ${size === 'default'
      ? css`
          max-height: 48px;
          padding: 16px 15.5px;
          font-size: var(--button-font-size);
        `
      : css`
          max-height: 32px;
          padding: 16px 10px;
          font-size: var(--button-font-size-small);
        `}
  `,
  fullWidth: ({ fullWidth }: ModifiersProps) => (fullWidth ? '100%' : 'auto'),
}

export const Container = styled.button<ModifiersProps & { hasIcon: boolean }>`
  ${({ variant, size, fixedWidth, isLoading, hasIcon, fullWidth }) => css`
    background: none;
    border-radius: var(--button-border-radius);
    width: ${fixedWidth ? '200px' : modifiers.fullWidth({ fullWidth })};
    cursor: pointer;
    transition: 180ms ease-in-out;
    font-family: Inter, 'sans-serif';
    font-weight: 700;
    text-decoration: none;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    :disabled {
      cursor: not-allowed;
    }

    ${!!variant && modifiers[variant]({ isLoading })}

    ${!!hasIcon && modifiers.withIcon({ size })}

    ${isLoading && modifiers.loading()}

    ${!!size && modifiers.sizeVariant({ size })}
  `}
`
