import styled, { css } from 'styled-components'

import { ContainerProps } from './types'

export const Flex = styled.div<ContainerProps>`
  box-sizing: border-box;
  display: flex;

  ${(props: ContainerProps) => css`
    flex-direction: ${props.direction};
    justify-content: ${props.justifyContent};
    align-items: ${props.alignItems};
    align-content: ${props.alignContent};
    gap: ${props.gap};
    flex-wrap: ${props.wrap};
    min-height: ${props.minHeight};
    height ${props.height};
    max-height: ${props.maxHeight};
    min-width: ${props.minWidth};
    width: ${props.width || '100%'};
    max-width: ${props.maxWidth};
  `}
`
