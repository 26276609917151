import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { Space, SpacingPropsKeys, SpaceValue, SpaceObjectItem } from './types'

const MEDIA = `(min-width: 767px)`

function generateStyles(
  props: SpaceObjectItem | SpaceValue,
  cssProperties: string[],
): FlattenSimpleInterpolation | undefined {
  if (typeof props === 'object') {
    const styles = css`
      ${cssProperties.map((item) => `${item}: ${Space[props.mob || props.desk || 0]}px;`)}
    `

    const stylesDesktop = css`
      ${cssProperties.map((item) => `${item}: ${Space[props.desk || props.mob || 0]}px;`)}
    `

    return css`
      ${styles}
      @media ${MEDIA} {
        ${stylesDesktop}
      }
    `
  }
  if (typeof props === 'string') {
    return css`
      ${cssProperties.map((item) => `${item}: ${Space[props]}px;`)}
    `
  }
  return undefined
}

const wrapperModifiersMargin = {
  m: (props: SpacingPropsKeys['m']) => generateStyles(props, ['margin']),
  mt: (props: SpacingPropsKeys['mt']) => generateStyles(props, ['margin-top']),
  mr: (props: SpacingPropsKeys['mr']) => generateStyles(props, ['margin-right']),
  mb: (props: SpacingPropsKeys['mb']) => generateStyles(props, ['margin-bottom']),
  ml: (props: SpacingPropsKeys['ml']) => generateStyles(props, ['margin-left']),
  mx: (props: SpacingPropsKeys['mx']) => generateStyles(props, ['margin-left', 'margin-right']),
  my: (props: SpacingPropsKeys['my']) => generateStyles(props, ['margin-top', ' margin-bottom']),
}

const wrapperModifiersPadding = {
  p: (props: SpacingPropsKeys['p']) => generateStyles(props, ['padding']),
  pt: (props: SpacingPropsKeys['pt']) => generateStyles(props, ['padding-top']),
  pr: (props: SpacingPropsKeys['pr']) => generateStyles(props, ['padding-right']),
  pb: (props: SpacingPropsKeys['pb']) => generateStyles(props, ['padding-bottom']),
  pl: (props: SpacingPropsKeys['pl']) => generateStyles(props, ['padding-left']),
  px: (props: SpacingPropsKeys['px']) => generateStyles(props, ['padding-left', 'padding-right']),
  py: (props: SpacingPropsKeys['py']) => generateStyles(props, ['padding-top', ' padding-bottom']),
}

export const Wrapper = styled.div<SpacingPropsKeys>`
  ${({ m, mt, mr, mb, ml, mx, my, p, pt, pr, pb, pl, px, py }) => css`
    ${m && wrapperModifiersMargin.m(m)}
    ${mt && wrapperModifiersMargin.mt(mt)}
    ${mr && wrapperModifiersMargin.mr(mr)}
    ${mb && wrapperModifiersMargin.mb(mb)}
    ${ml && wrapperModifiersMargin.ml(ml)}
    ${mx && wrapperModifiersMargin.mx(mx)}
    ${my && wrapperModifiersMargin.my(my)}
 
    ${p && wrapperModifiersPadding.p(p)}
    ${pt && wrapperModifiersPadding.pt(pt)}
    ${pr && wrapperModifiersPadding.pr(pr)}
    ${pb && wrapperModifiersPadding.pb(pb)}
    ${pl && wrapperModifiersPadding.pl(pl)}
    ${px && wrapperModifiersPadding.px(px)}
    ${py && wrapperModifiersPadding.py(py)}
  `}
`
