import React from 'react'

import * as S from './styles'
import * as Types from './types'

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/data-display-flex-since-3-4-0--simple)
 *
 * **Code example**
 * ```tsx
 * import React, { useState } from 'react'
 *
 * import { Flex } from '@interco/inter-ui/components/Flex'
 *
 * export const MyPage = () => {
 *   return (
 *     <Flex
 *       justifyContent="space-between"
 *       direction="column"
 *       alignContent="flex-end"
 *       alignItems="baseline"
 *       gap="16px"
 *     >
 *       Display Flex
 *    </Flex>
 *  )
 * }
 * ```
 */
export const Flex = ({
  direction = 'column',
  justifyContent = 'space-between',
  alignItems = 'start',
  alignContent = 'start',
  ...divProps
}: Types.FlexProps) => (
  <S.Flex
    direction={direction}
    justifyContent={justifyContent}
    alignItems={alignItems}
    alignContent={alignContent}
    {...divProps}
  />
)

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/data-display-flex-since-3-4-0--simple)
 *
 * **Code example**
 * ```tsx
 * import React, { useState } from 'react'
 *
 * import { FlexRow } from '@interco/inter-ui/components/Flex'
 *
 * export const MyPage = () => {
 *   return (
 *     <FlexRow
 *       justifyContent="space-between"
 *       alignContent="flex-end"
 *       alignItems="baseline"
 *       gap="16px"
 *     >
 *       Display Flex
 *    </FlexRow>
 *  )
 * }
 * ```
 */
export const FlexRow = (props: Omit<Types.FlexProps, 'direction'>) => (
  <Flex direction="row" {...props} />
)

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/data-display-flex-since-3-4-0--simple)
 *
 * **Code example**
 * ```tsx
 * import React, { useState } from 'react'
 *
 * import { FlexColumn } from '@interco/inter-ui/components/Flex'
 *
 * export const MyPage = () => {
 *   return (
 *     <FlexColumn
 *       justifyContent="space-between"
 *       alignContent="flex-end"
 *       alignItems="baseline"
 *       gap="16px"
 *     >
 *       Display Flex
 *    </FlexColumn>
 *  )
 * }
 * ```
 */
export const FlexColumn = (props: Omit<Types.FlexProps, 'direction'>) => (
  <Flex direction="column" {...props} />
)
