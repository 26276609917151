import React, { memo, ChangeEvent } from 'react'

import RadioGroupContext from './context'

type BaseRadioGroupProps = DefaultHTMLAttrs<HTMLDivElement> & {
  name: string
  value?: string
  disabled?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

export type RadioGroupProps = PropsWithRequiredChildren<BaseRadioGroupProps>

const Component = ({
  children,
  name,
  disabled,
  value = '',
  onChange,
  ...attrs
}: RadioGroupProps) => (
  <RadioGroupContext.Provider value={{ name, value, disabled }}>
    <div {...attrs} role="radiogroup" onChange={(event) => onChange?.(event)}>
      {children}
    </div>
  </RadioGroupContext.Provider>
)

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/forms-radio-since-v1-0-0--simple)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/1JFZTVxalrwvmUHCWBf2Pt/DS-%2F-Components-%2F-Mobile-%26-App?node-id=4-631&t=NA4uIDFsJjbTemvb-0)
 *
 * **Code example**
 * ```tsx
 * import React, { useState } from 'react'
 *
 * import { RadioGroup } from '@interco/inter-ui/components/RadioGroup'
 * import { Radio } from '@interco/inter-ui/components/Radio'
 *
 * export const MyPage = () => {
 *   const [selected, setSelected] = useState<string>('1')
 *
 *   return (
 *     <RadioGroup
 *       name="options"
 *       value={selected}
 *       onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSelected(event.target.value)}
 *       style={{ display: 'flex' }}
 *     >
 *       <Radio id="option-1" value="1" />
 *       <Radio id="option-2" value="2" />
 *       <Radio id="option-3" value="3" />
 *       <Radio id="option-4" value="4" />
 *     </RadioGroup>
 *   )
 * }
 * ```
 */
export const RadioGroup = memo(Component)
