import styled from 'styled-components'

export const MainContainer = styled.div`
  width: 100%;
`

export const TabList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  border-bottom: 1px solid var(--gray200);
  font-family: Sora, sans-serif;
  width: 100%;
`

export const Tab = styled.div<{ current: boolean; disabled: boolean }>`
  position: relative;
  margin: 0;
  padding: 0.5rem 1.5rem;
  flex-shrink: 0;
  cursor: pointer;
  color: var(--tab-label-text-color);
  font-size: 0.875rem;

  ${(props) =>
    props.disabled &&
    `
    color: var(--tab-label-disabled-text-color);
    cursor: not-allowed;
  `}

  ${(props) =>
    props.current &&
    `
    color: var(--tab-label-selected-text-color);
    font-weight: bold;
    &::after {
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 4px;
      bottom: -1px;
      left: 0;
      background-color: var(--tab-bar-background-color);
      border-radius: var(--tab-bar-border-radius);
      border-top-right-radius: 33px;
      border-top-left-radius: 33px;
    }
  `};
`

export const TabPanel = styled.div`
  padding: 1rem;
  background: var(--tab-panel-border-color);
`
