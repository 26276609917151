export enum Space {
  xxxs = 4,
  xxs = 8,
  xs = 16,
  sm = 24,
  md = 32,
  lg = 40,
  xl = 64,
  xxl = 96,
  xxxl = 128,
}

export type SpaceObjectItem = {
  mob?: SpaceValue
  desk?: SpaceValue
}

export type SpaceValue = keyof typeof Space | undefined

export type SpacingTypes =
  | 'm'
  | 'mt'
  | 'mr'
  | 'mb'
  | 'ml'
  | 'mx'
  | 'my'
  | 'p'
  | 'pt'
  | 'pr'
  | 'pb'
  | 'pl'
  | 'px'
  | 'py'

export type SpacingPropsKeys = {
  m?: SpaceObjectItem | SpaceValue
  mt?: SpaceObjectItem | SpaceValue
  mr?: SpaceObjectItem | SpaceValue
  mb?: SpaceObjectItem | SpaceValue
  ml?: SpaceObjectItem | SpaceValue
  mx?: SpaceObjectItem | SpaceValue
  my?: SpaceObjectItem | SpaceValue
  p?: SpaceObjectItem | SpaceValue
  pt?: SpaceObjectItem | SpaceValue
  pr?: SpaceObjectItem | SpaceValue
  pb?: SpaceObjectItem | SpaceValue
  pl?: SpaceObjectItem | SpaceValue
  px?: SpaceObjectItem | SpaceValue
  py?: SpaceObjectItem | SpaceValue
}

export type SpacingProps = RequireAtLeastOne<SpacingPropsKeys, SpacingTypes> & {
  children?: React.ReactNode
} & DefaultHTMLAttrs<HTMLDivElement>
