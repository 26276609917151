import React, { CSSProperties } from 'react'

import { Text } from '../Text'
import * as S from './styles'

export type TabDefinition = {
  tabId: string | number
  label: string | React.ReactNode
  disabled?: boolean
  content: React.ReactNode
}

export type TabPagerProps = {
  currentTabId: string | number
  tabDefinitions: TabDefinition[]
  onTabSelect: (tabId: string | number) => void
  mainContainerStyle?: CSSProperties
  tabListStyle?: CSSProperties
  tabPanelStyle?: CSSProperties
  tabItemStyle?: CSSProperties
}

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/navigation-tab-pager-since-v2-1-15--simple)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/7QVC2u96bJmyW8qQ8mBNb3/DS-%2F-Components-%2F-Global?node-id=8555-3474&t=D5EfUKomWuHwhfno-0)
 *
 * **Code example**
 * ```tsx
 * import React, { useState, useEffect } from 'react'
 *
 * import { TabPager, TabDefinition } from '@interco/inter-ui/components/TabPager'
 *
 * const MyOtherComponent = () => {
 *   useEffect(() => {
 *     console.log('Renderizado: MyOtherComponent')
 *   }, [])
 *
 *   return <p>Hello World</p>
 * }
 *
 * export const MyPage = () => {
 *   const [currentTab, setCurrentTab] = useState<string | number>('a')
 *
 *   const tabDefinitions: TabDefinition[] = [
 *     {
 *       tabId: 'a',
 *       label: 'Tab A',
 *       content: <p>This is the A tab</p>,
 *     },
 *     {
 *       tabId: 'b',
 *       label: 'Tab B',
 *       content: <MyOtherComponent />,
 *     },
 *     {
 *       tabId: 'c',
 *       label: 'Tab C',
 *       content: <p>This is the C tab</p>,
 *     },
 *   ]
 *
 *   const handleTabChange = (tabId: string | number) => {
 *     setCurrentTab(tabId)
 *   }
 *
 *   return (
 *     <TabPager
 *       currentTabId={currentTab}
 *       onTabSelect={handleTabChange}
 *       tabDefinitions={tabDefinitions}
 *     />
 *   )
 * }
 * ```
 */
export const TabPager = React.memo(
  ({
    currentTabId,
    tabDefinitions,
    onTabSelect,
    mainContainerStyle = {},
    tabItemStyle = {},
    tabListStyle = {},
    tabPanelStyle = {},
  }: TabPagerProps) => (
    <S.MainContainer data-testid="tabPagerContainer" style={mainContainerStyle}>
      <S.TabList role="tablist" style={tabListStyle}>
        {tabDefinitions.map((tabDefinition) => {
          const isSelected = tabDefinition.tabId === currentTabId
          const isDisabled = !!tabDefinition.disabled
          const color = isSelected
            ? 'var(--tab-label-selected-text-color)'
            : 'var(--tab-label-text-color)'

          return (
            <S.Tab
              role="tab"
              aria-selected={isSelected}
              key={tabDefinition.tabId}
              current={tabDefinition.tabId === currentTabId}
              disabled={isDisabled}
              style={tabItemStyle}
              onClick={() => !isDisabled && !isSelected && onTabSelect(tabDefinition.tabId)}
            >
              {typeof tabDefinition.label === 'string' ? (
                <Text
                  variant="body-2"
                  bold
                  style={{ color: isDisabled ? 'var(--tab-label-disabled-text-color)' : color }}
                >
                  {tabDefinition.label}
                </Text>
              ) : (
                tabDefinition.label
              )}
            </S.Tab>
          )
        })}
      </S.TabList>

      <S.TabPanel role="tabpanel" style={tabPanelStyle}>
        {tabDefinitions.find((tabDefinition) => tabDefinition.tabId === currentTabId)?.content}
      </S.TabPanel>
    </S.MainContainer>
  ),
)
