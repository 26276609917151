import styled, { css } from 'styled-components'

type StyledContainerProps = {
  disabled: boolean
}

type StyledTextProps = StyledContainerProps & {
  error: boolean
  success: boolean
}

const textModifiers = {
  success: () => css`
    color: var(--input-success-label-text-color);
  `,
  error: () => css`
    color: var(--input-error-label-text-color);
  `,
  disabled: () => css`
    color: var(--input-disabled-label-text-color);
  `,
}

export const Container = styled.div<{ isDisabled: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ isDisabled }) => isDisabled && 'cursor: not-allowed;'}
`

export const IndicatorContainer = styled.div`
  margin: 0px 5px;
  display: flex;
`

export const Title = styled.div`
  display: flex;
  margin-bottom: 4px;
`

export const Label = styled.label<StyledTextProps>`
  ${({ error, success, disabled }) => css`
    font-size: var(--input-label-font-size);
    line-height: 16px;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: 0;
    font-family: Inter;
    color: var(--input-label-text-color);
    ${disabled && textModifiers.disabled()}
    ${error && textModifiers.error()}
    ${success && textModifiers.success()}
  `}
`

export const InfoText = styled.div<StyledTextProps>`
  ${({ error, success, disabled }) => css`
    font-family: Inter;
    font-size: var(--input-info-font-size);
    text-align: right;
    line-height: 16px;
    bottom: 0;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: var(--input-info-text-color);
    ${disabled && textModifiers.disabled()}
    ${error && textModifiers.error()}
    ${success && textModifiers.success()}
  `}
`
