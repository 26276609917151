import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-flow: column wrap;

  span + span {
    margin-top: 12px;
    margin-bottom: 20px;
  }

  & > div {
    margin: auto;
    margin-bottom: 20px;
  }
`
