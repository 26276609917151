import styled, { css } from 'styled-components'

const track = css`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 8px;
`

const thumb = css`
  -webkit-appearance: none;
  height: 24px;
  width: 24px;
  background-color: var(--input-range-thumb-color);
  border-radius: 50%;
  cursor: pointer;
  margin-top: -8px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.05), 0px 3px 3px rgba(0, 0, 0, 0.05),
    0px 2px 2px rgba(0, 0, 0, 0.1);
`

export const InputRange = styled.input`
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: block;
  width: 100%;
  height: 8px;
  background-color: var(--input-range-track-color);
  border-radius: 8px;
  outline: none;

  &::-webkit-slider-runnable-track {
    ${track};
  }

  &::-moz-track {
    ${track};
  }

  &::-ms-track {
    ${track};
  }

  &::-webkit-slider-thumb {
    ${thumb};
  }

  &::-moz-range-thumb {
    ${thumb};
  }

  &::-ms-thumb {
    ${thumb};
  }
`
