import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ElementType,
  memo,
  ReactNode,
} from 'react'

import { DEFAULT_CLICK_HANDLER } from '@/common/constants'

import * as S from './styles'

type ButtonTypes = AnchorHTMLAttributes<HTMLAnchorElement> | ButtonHTMLAttributes<HTMLButtonElement>

export type ButtonProps = {
  icon?: ReactNode
  as?: ElementType
  onClick?: () => void
  variant?: 'primary' | 'secondary' | 'link'
  size?: 'default' | 'small'
  fixedWidth?: boolean
  isLoading?: boolean
  disabled?: boolean
  fullWidth?: boolean
} & ButtonTypes

const Component = ({
  variant = 'primary',
  size = 'default',
  className = '',
  fixedWidth = false,
  isLoading = false,
  children,
  as = 'button',
  icon,
  type = 'button',
  onClick,
  fullWidth = false,
  ...attrs
}: ButtonProps) => {
  const handleClick = isLoading ? DEFAULT_CLICK_HANDLER : onClick

  return (
    <S.Container
      onClick={handleClick}
      isLoading={isLoading}
      hasIcon={!!icon}
      variant={variant}
      className={className}
      size={size}
      as={as}
      fixedWidth={fixedWidth}
      type={as === 'a' ? undefined : type}
      fullWidth={fullWidth}
      {...attrs}
    >
      {icon ? (
        <>
          <span>{children}</span>
          {icon}
        </>
      ) : (
        children
      )}
    </S.Container>
  )
}

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/forms-button-since-v1-0-0--all)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/7QVC2u96bJmyW8qQ8mBNb3/DS-%2F-Components-%2F-Global?node-id=5-19&t=qKLhC8XvmTJTsQ5U-0)
 *
 * **Code example**
 * ```tsx
 * import React, { useCallback } from 'react'
 *
 * import { Button } from '@interco/inter-ui/components/Button'
 *
 * export const MyPage = () => {
 *   const onButtonClick = useCallback(() => {
 *     //
 *   }, [])
 *
 *   return (
 *     <Button onClick={onButtonClick}>
 *       My Button Text
 *     </Button>
 *   )
 * }
 * ```
 */
export const Button = memo(Component)
