import React from 'react'

import * as S from './styles'

export const ISafeIcon = ({ width = 80, height = 80, ...attrs }: DefaultSVGProps) => (
  <S.Container>
    <S.ISafeOutline width={+width / 2.5} height={+height / 2.5} color="#EB7000" />

    <S.Svg fill="none" viewBox="0 0 66 66" width={width} height={height} {...attrs}>
      <circle cx="33" cy="33" fill="none" r="28" stroke="rgba(24, 24, 24, 0.1)" strokeWidth="4" />

      <S.Circle
        cx="33"
        cy="33"
        fill="none"
        r="28"
        stroke="currentColor"
        strokeDasharray="1, 174"
        strokeDashoffset="306"
        strokeLinecap="round"
        strokeWidth="4"
      />
    </S.Svg>
  </S.Container>
)
