import React, { Ref } from 'react'

import SelectComponent, { SelectInstance, GroupBase, components, OptionProps } from 'react-select'
import styled from 'styled-components'

import ChevronDown from '@interco/icons/orangeds/XL/chevron-down'
import LockIcon from '@interco/icons/bidis/v2/action/lock'

import * as S from './styles'
import { SelectProps, SelectOption } from './types'
import { Flex } from '../Flex/styles'

export type { SelectProps, SelectOption } from './types'

export type { SingleValue, MultiValue } from 'react-select'

type PickColors = {
  success: boolean
  successColor?: string
  error: boolean
  errorColor?: string
  defaultColor?: string
  disabled: boolean
  disabledColor?: string
}

const pickColorBasedOnStatus = ({
  success,
  successColor = 'var(--success100)',
  error,
  errorColor = 'var(--error100)',
  defaultColor = 'var(--gray100)',
  disabled,
  disabledColor = 'var(--gray100)',
}: PickColors) => {
  if (disabled) return disabledColor
  if (success) return successColor
  if (error) return errorColor
  return defaultColor
}

const statusColors = {
  successColor: 'var(--success500)',
  errorColor: 'var(--error500)',
  disabledColor: 'var(--gray300)',
}

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/forms-select-since-v3-6-0--basic)
 *
 * **Code example**
 * ```tsx
 * import React, { useState } from 'react'
 *
 * import { Select, SingleValue, SelectOption } from '@interco/inter-ui/components/Select'
 *
 * const options: SelectOption[] = [
 *   { label: 'Option 1', value: 'Option 1' },
 *   { label: 'Option 2', value: 'Option 2' },
 *   { label: 'Option 3', value: 'Option 3' },
 * ]
 *
 * export const MyPage = () => {
 *   const [selected, setSelected] = useState<SingleValue<SelectOption>>(null)
 *
 *   return (
 *     <Select
 *       options={options}
 *       placeholder="Escolha uma opção ..."
 *       onChange={(value) => setSelected(value)}
 *     />
 *   )
 * }
 * ```
 */
export const Select = React.forwardRef(
  (
    {
      id,
      options,
      onChange,
      placeholder = 'Selecione',
      values = [],
      styles: customStyles,
      error = false,
      success = false,
      infoText,
      disabled = false,
      label,
      ...attrs
    }: SelectProps,
    ref?: Ref<SelectInstance<SelectOption, boolean, GroupBase<SelectOption>>>,
  ) => {
    const Security = styled(LockIcon)`
      fill: ${pickColorBasedOnStatus({
        success,
        error,
        disabled,
        defaultColor: 'var(--highlight-color)',
        ...statusColors,
      })};
    `

    const ArrowDown = styled(ChevronDown)`
      path: ${pickColorBasedOnStatus({
        success,
        error,
        disabled,
        defaultColor: 'var(--highlight-color)',
        ...statusColors,
      })};
    `

    const IndicatorIcon = React.useCallback(
      () => (
        <S.IndicatorContainer>
          {disabled ? (
            <Security height={24} width={24} color="var(--primary500)" />
          ) : (
            <ArrowDown height={24} width={24} color="var(--primary500)" />
          )}
        </S.IndicatorContainer>
      ),
      [disabled, Security, ArrowDown],
    )

    const { Option } = components
    const CustomSelectOption = (
      props: JSX.IntrinsicAttributes & OptionProps<SelectOption, boolean, GroupBase<SelectOption>>,
    ) => {
      const {
        data: { icon, label: labelOption },
      } = props
      return (
        <Option {...props}>
          <Flex direction="row" gap="5px">
            {icon}
            {labelOption}
          </Flex>
        </Option>
      )
    }

    return (
      <S.Container isDisabled={disabled}>
        {label && (
          <S.Title>
            <S.Label htmlFor={id} error={error} success={success} disabled={disabled}>
              {label}
            </S.Label>
          </S.Title>
        )}

        <SelectComponent
          ref={ref}
          styles={{
            option: (defaultReactSelectStyles, { isFocused, isSelected }) => ({
              ...defaultReactSelectStyles,
              backgroundColor: isFocused
                ? 'var(--primary100)'
                : defaultReactSelectStyles.backgroundColor,
              '&:hover': {
                backgroundColor: isSelected ? 'var(--highlight-color)' : 'var(--primary100)',
              },
              color: isFocused ? 'var(--typography500)' : 'inherit',
            }),
            control: (defaultReactSelectStyles, _status) => ({
              ...defaultReactSelectStyles,
              backgroundColor: pickColorBasedOnStatus({
                success,
                error,
                disabled,
              }),
              borderRadius: 'var(--radiiMd)',
              borderColor: 'transparent',
              color: pickColorBasedOnStatus({
                success,
                error,
                disabled,
                defaultColor: 'var(--highlight-color)',
                ...statusColors,
              }),
              boxShadow: defaultReactSelectStyles.isFocused ? 'none' : 'none',
              ':hover': {
                borderColor: 'transparent',
              },
              height: 48,
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            dropdownIndicator: (defaultReactSelectStyles) => ({
              ...defaultReactSelectStyles,
              '&:hover': {
                color: pickColorBasedOnStatus({
                  success,
                  error,
                  disabled,
                  defaultColor: 'var(--highlight-color)',
                  ...statusColors,
                }),
              },
            }),
            placeholder: (defaultReactSelectStyles, _status) => ({
              ...defaultReactSelectStyles,
              color: pickColorBasedOnStatus({
                success,
                error,
                disabled,
                defaultColor: 'var(--gray300)',
                ...statusColors,
              }),
              fontFamily: 'Inter',
              fontWeight: '700',
            }),
            menu: (defaultReactSelectStyles, _styles) => ({
              ...defaultReactSelectStyles,
              backgroundColor: 'var(--neutral-theme)',
            }),
            singleValue: (defaultReactSelectStyles, _status) => ({
              ...defaultReactSelectStyles,
              color: pickColorBasedOnStatus({
                success,
                error,
                disabled,
                defaultColor: 'var(--gray400)',
                successColor: 'var(--input-success-label-text-color)',
                errorColor: 'var(--input-error-label-text-color)',
                disabledColor: 'var(--input-disabled-label-text-color)',
              }),
              fontFamily: 'Inter',
              fontSize: 'var(--input-placeholder-font-size)',
            }),
            ...customStyles,
          }}
          placeholder={placeholder}
          defaultValue={values}
          options={options}
          onChange={onChange}
          isDisabled={disabled}
          noOptionsMessage={() => 'Nenhuma opção'}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary: 'var(--highlight-color)',
            },
          })}
          {...attrs}
          components={{
            Option: CustomSelectOption,
            DropdownIndicator: IndicatorIcon,
          }}
        />

        <S.InfoText error={error} success={success} disabled={disabled}>
          {infoText}
        </S.InfoText>
      </S.Container>
    )
  },
)
