import { css, FlattenSimpleInterpolation } from 'styled-components'

import * as Types from './types'

type ModifiersProps = Partial<Types.TextProps>

export const DEFAULT_COLOR_WEIGHT: Types.ColorWeight = 400
export const TITLE_COLOR_WEIGHT: Types.ColorWeight = 500

const modifiersSora = ({ semiBold }: ModifiersProps) => css`
  font-family: Sora;
  font-style: normal;
  font-weight: ${semiBold ? 600 : 'normal'};
`

const SoraVariants: Record<
  Types.SoraVariantsProps['variant'],
  (props: ModifiersProps) => FlattenSimpleInterpolation
> = {
  'web-head': (props: ModifiersProps) => css`
    ${modifiersSora(props)}
    font-size: 96px;
    line-height: 121px;
  `,
  'web-title': (props: ModifiersProps) => css`
    ${modifiersSora(props)}
    font-size: 72px;
    line-height: 91px;
  `,
  'web-subtitle': (props: ModifiersProps) => css`
    ${modifiersSora(props)}
    font-size: 64px;
    line-height: 81px;
  `,
  'headline-master': (props: ModifiersProps) => css`
    ${modifiersSora(props)}
    font-size: 48px;
    line-height: 60px;
  `,
  'headline-super': (props: ModifiersProps) => css`
    ${modifiersSora(props)}
    font-size: 40px;
    line-height: 50px;
  `,
  'headline-big': (props: ModifiersProps) => css`
    ${modifiersSora(props)}
    font-size: 32px;
    line-height: 40px;
  `,
  'headline-h1': (props: ModifiersProps) => css`
    ${modifiersSora(props)}
    font-size: 24px;
    line-height: 30px;
  `,
  'headline-h2': (props: ModifiersProps) => css`
    ${modifiersSora(props)}
    font-size: 20px;
    line-height: 25px;
  `,
  'headline-h3': (props: ModifiersProps) => css`
    ${modifiersSora(props)}
    font-size: 16px;
    line-height: 20px;
  `,
}

const modifiersInter = ({ bold }: ModifiersProps) => css`
  font-family: Inter;
  font-style: normal;
  font-weight: ${bold ? 'bold' : 'normal'};
`

const InterVariants: Record<
  Types.InterVariantsProps['variant'],
  (props: ModifiersProps) => FlattenSimpleInterpolation
> = {
  'body-1': (props: ModifiersProps) => css`
    ${modifiersInter(props)}
    font-size: 18px;
    line-height: 22px;
  `,
  'body-2': (props: ModifiersProps) => css`
    ${modifiersInter(props)}
    font-size: 16px;
    line-height: 19px;
  `,
  'body-3': (props: ModifiersProps) => css`
    ${modifiersInter(props)}
    font-size: 14px;
    line-height: 17px;
  `,
  'caption-1': (props: ModifiersProps) => css`
    ${modifiersInter(props)}
    font-size: 12px;
    line-height: 15px;
  `,
  'caption-2': (props: ModifiersProps) => css`
    ${modifiersInter(props)}
    font-size: 10px;
    line-height: 12px;
  `,
}

export const modifiers = {
  ...SoraVariants,
  ...InterVariants,
}

export const commonStyles = (props: Types.TextProps) => css`
  color: ${`var(--${props.color}${props.colorWeight})`};
  ${modifiers[props.variant](props)}
`
