import { InterWebviewBridge } from 'core/InterWebviewBridge';
import { WbSessionActions } from 'enums';
const InterWbNavigate = () => {
    const iWb = InterWebviewBridge.getInstance();
    return {
        /**
         * Requisita um go back
         * @returns void
         */
        requestGoBack() {
            return iWb.execute({ action: WbSessionActions.REQUEST_GO_BACK });
        },
        /**
         * Abre um deeplink no app
         * @param url  - e.g: "bancointer://mei"
         * @returns
         */
        openDeepLink(url) {
            return iWb.execute({
                action: WbSessionActions.OPEN_DEEPLINK,
                metadata: { url },
            });
        },
        /**
         * Requisita a babi
         *
         * @returns void
         */
        requestBabi() {
            return iWb.execute({ action: WbSessionActions.REQUEST_GO_BABI });
        },
        /**
         * Abre um link no browser nativo
         * @param url  - e.g: "https://www.bancointer.com.br"
         * @returns
         */
        openNativeBrowser(url) {
            return iWb.execute({
                action: WbSessionActions.OPEN_NATIVE_BROWSER,
                metadata: { url },
            });
        },
        /**
         * Realiza chamada telefonica
         *
         * @param phoneNumber - número do telefone
         * @returns void
         */
        callPhoneNumber(phoneNumber) {
            return iWb.execute({
                action: WbSessionActions.CALL_PHONE_NUMBER,
                metadata: { phoneNumber },
            });
        },
        /**
         * Abre aplicativo de mapa na localização informada
         *
         * @param latitude - latitude da localização
         * @param longitude - longitude da localização
         * @returns void
         */
        openLocationMap(latitude, longitude) {
            return iWb.execute({
                action: WbSessionActions.OPEN_LOCATION_MAP,
                metadata: {
                    latitude,
                    longitude,
                },
            });
        },
    };
};
export const interWbNavigate = InterWbNavigate();
