import React, { useCallback, useEffect, useRef, CSSProperties } from 'react'

import * as S from './styles'

export type InputRangeProps = {
  min: number
  max: number
  value: number
  onChange: (value: number) => void
  dataTestId?: string
  inputStyles?: CSSProperties
}

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/forms-inputrange-since-v2-2-0--primary)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/7QVC2u96bJmyW8qQ8mBNb3/DS-%2F-Components-%2F-Global?node-id=8555-3463&t=iQziLwcdqMjotC87-0)
 *
 * **Code example**
 * ```tsx
 * import React, { useState } from 'react'
 *
 * import { InputRange } from '@interco/inter-ui/components/InputRange'
 *
 * export const MyPage = () => {
 *   const [value, setValue] = useState<number>(3)
 *
 *   return (
 *     <InputRange
 *       min={0}
 *       max={100}
 *       value={value}
 *       onChange={(newValue: number) => setValue(newValue)}
 *     />
 *   )
 * }
 * ```
 */
export const InputRange = React.memo(
  ({
    min,
    max,
    value,
    onChange,
    dataTestId = 'input-range',
    inputStyles = {},
  }: InputRangeProps) => {
    const inputRangeReference = useRef<HTMLInputElement>(null)

    const handleInputRangeBackground = useCallback(
      (newValue: number) => {
        if (!inputRangeReference.current) return

        const valuePercent = ((newValue - min) / (max - min)) * 100
        inputRangeReference.current.style.background = `linear-gradient(to right, var(--input-range-track-filled-color) ${valuePercent}%, var(--input-range-track-color) ${valuePercent}%)`
      },
      [max, min],
    )

    const onInputChange = useCallback(
      (event) => {
        onChange(event.target.value)
        handleInputRangeBackground(event.target.value)
      },
      [onChange, handleInputRangeBackground],
    )

    useEffect(() => {
      handleInputRangeBackground(value)
    }, [value, handleInputRangeBackground])

    return (
      <S.InputRange
        data-testid={dataTestId}
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={onInputChange}
        ref={inputRangeReference}
        style={inputStyles}
      />
    )
  },
)
