import React from 'react'

import * as S from './styles'
import { AlertModal, AlertModalProps } from '../AlertModal'

export type FeedbackModalProps = Omit<Omit<AlertModalProps, 'header'>, 'preHeader'> & {
  figure: React.ReactElement
  title: React.ReactNode
}

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/modals-feedback-modal-since-v2-1-12--success-feedback)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/FS12HnaqvY6B4K9mkTCSrr/DS-%2F-Components-%2F-Desktop?node-id=2539-7198&t=RabwOuj1svlNaWmz-0)
 *
 * **Code example**
 * ```tsx
 * import React, { useState, useCallback } from 'react'
 * import successFigure from './some-success-figure.svg'
 *
 * export const MyPage = () => {
 *   const [visible, setVisible] = useState<boolean>(false)
 *
 *   const closeModal = useCallback(() => {
 *     setVisible(false)
 *   }, [setVisible])
 *
 *   const handlePrimaryAction = useCallback(() => {
 *     setVisible(false)
 *     // Other actions
 *   }, [closeModal])
 *
 *   return (
 *     <>
 *       <Button onClick={() => setVisible(true)}>Trigger Feedback</Button>
 *
 *       <FeedbackModal
 *         figure={<img alt="success" src={successFigure} width={200} height={200} />}
 *         visible={visible}
 *         title="Registrado com sucesso!"
 *         onPrimary={handlePrimaryAction}
 *         onClose={closeModal}
 *         onClickOutside={closeModal}
 *       >
 *         O feedback de sucesso apresenta uma figura, um título e uma mensagem
 *         textual descritiva, além de uma ação primária associada ao botão. Todo
 *         o conteúdo é alinhado verticalmente no centro.
 *       </FeedbackModal>
 *     </>
 *   )
 * }
 * ```
 */
export const FeedbackModal = ({
  figure,
  title,
  visible,
  children,
  style = {},
  ...baseModalProps
}: FeedbackModalProps) => (
  <AlertModal
    {...baseModalProps}
    preHeader={figure}
    title={<S.Title>{title}</S.Title>}
    header={null}
    visible={visible}
    style={{ ...style, alignItems: 'center' }}
  >
    <S.Body>{children}</S.Body>
  </AlertModal>
)
