import styled from 'styled-components'

import * as Types from './types'
import { commonStyles, DEFAULT_COLOR_WEIGHT, TITLE_COLOR_WEIGHT } from './styles'

export * from './types'

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/data-display-text-since-2-0-10--all)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/RYPyo16YccXBSelucyi2yM/DS-%2F-Foundations?node-id=20283-4658&t=bKUJmVS5OBEZawLj-0)
 *
 * **Code example**
 * ```tsx
 * import React from 'react'
 *
 * import { Text } from '@interco/inter-ui/components/Text'
 *
 * export const MyPage = () => {
 *   return <Text variant="web-head">Inter</Text>
 * }
 * ```
 */
export const Text = styled.span<Types.TextProps>`
  ${({ color = 'typography', ...props }) => {
    let colorWeight: Types.ColorWeight = DEFAULT_COLOR_WEIGHT

    if (
      [
        'headline-master',
        'headline-super',
        'headline-big',
        'headline-h1',
        'headline-h2',
        'headline-h3',
      ].includes(props.variant)
    ) {
      colorWeight = TITLE_COLOR_WEIGHT
    }

    if (props.colorWeight) {
      colorWeight = props.colorWeight
    }

    return commonStyles({ color, colorWeight, ...props })
  }}
`
