import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const RulesContainer = styled.div`
  margin: 32px 0px;
`

export const Rule = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  & > span {
    margin-left: 8px;
  }
`
